import type { Component } from "solid-js";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import { CircularAvatar, AvatarSize } from "./avatars";
import { useWire } from "~/wire";
import { TbLogout } from "solid-icons/tb";
import { useNavigate } from "@solidjs/router";
import { StDropdown } from "./popups/StDropdown";

export const UserAvatarWithMenu: Component = () => {
  const wire = useWire();
  const navigate = useNavigate();

  const userID = () => {
    if (!isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)) {
      return undefined;
    }
    return wire.services.identity.snapshot.context.identity.userId;
  };

  const onSignOut = async () => {
    await wire.services.identity.signOut();
    navigate("/");
  };

  return (
    <StDropdown
      theme="default"
      opts={{
        placement: "bottom-end",
      }}
      items={[
        {
          kind: "item",
          content: "Sign out",
          icon: TbLogout,
          props: { onClick: onSignOut },
        },
      ]}
    >
      <CircularAvatar size={AvatarSize.Mini} userId={userID()} />
    </StDropdown>
  );
};
