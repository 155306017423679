import type { Component } from "solid-js";
import { twMerge } from "tailwind-merge";
import { AvatarSize, type AvatarProps } from "~/components/avatars/types";
import { extractInitials } from "./behavior";

export const CircularAvatar: Component<AvatarProps> = (props) => {
  const parsePath = () => {
    if (!props.userId && !props.src) return "/images/guest-avatar.png";

    const prefix = "https://storytell.ai/";
    // Could be self-hosted, such as "Storytell"s bot image
    if (props.src?.startsWith(prefix)) {
      return props.src.slice(prefix.length - 1);
    }
    let bucket = "storytell-avatars";
    switch (RUNNING_ENV) {
      case "local":
      case "preview":
      case "development":
        bucket = "storytell-avatars-development";
        break;
      case "staging":
        bucket = "storytell-uc-assets-staging";
        break;
    }

    return `https://storage.googleapis.com/${bucket}/${props.userId}`;
  };

  return (
    <span
      class={twMerge(
        "m-1 ring-2 ring-white inline-flex items-center justify-center rounded-full bg-gray-500 relative",
        props.class,
      )}
      classList={{
        "w-6 h-6": props.size === AvatarSize.Micro,
        "w-9 h-9": props.size === AvatarSize.Mini,
        "w-12 h-12": props.size === AvatarSize.Default,
      }}
    >
      <span class="absolute text-xs font-medium leading-none text-white">
        {extractInitials(props.fullName ?? "Guest")}
      </span>
      <img
        referrerpolicy="no-referrer"
        class="absolute inline-block rounded-full"
        classList={{
          "w-6 h-6": props.size === AvatarSize.Micro,
          "w-9 h-9": props.size === AvatarSize.Mini,
          "w-12 h-12": props.size === AvatarSize.Default,
        }}
        src={`${parsePath() ?? "/images/guest-avatar.jpg?fallback"}`}
        // @ts-expect-error
        onerror="this.style.display='none'"
        alt={props.fullName || "Avatar"}
      />
    </span>
  );
};
